import { createAction, props } from '@ngrx/store';
import { ActionBase } from '../action-base.interface';
import { Dependency, DependencyValue } from 'processdelight-angular-components';

export const removeDependencies = createAction(
  '[Dependency] Remove dependencies',
  props<{ ids: string[] } & ActionBase<string[]>>()
);

export const removeDependenciesResolved = createAction(
  '[Dependency] Remove dependencies resolved',
  props<{ ids: string[] }>()
);

export const updateDependencies = createAction(
  '[Dependency] Update dependencies',
  props<{ dependencies: Dependency[] } & ActionBase<Dependency[]>>()
);

export const updateDependenciesResolved = createAction(
  '[Dependency] Update dependencies resolved',
  props<{ updatedDependencies: Dependency[] }>()
);

export const addDependencies = createAction(
  '[Dependency] Add dependencies',
  props<
    {
      dependencies: Dependency[];
    } & ActionBase<Dependency[]>
  >()
);

export const addDependenciesResolved = createAction(
  '[Dependency] Add dependencies resolved',
  props<{ addedDependencies: Dependency[] }>()
);

export const updateDependencyValues = createAction(
  '[DependencyValue] Update dependency values',
  props<
    { dependencyValues: DependencyValue[] } & ActionBase<DependencyValue[]>
  >()
);

export const updateDependencyValuesResolved = createAction(
  '[DependencyValue] Update dependency values resolved',
  props<{ updatedDependencyValues: DependencyValue[] }>()
);

export const addDependencyValues = createAction(
  '[DependencyValue] Add dependency values',
  props<
    { dependencyValues: DependencyValue[] } & ActionBase<DependencyValue[]>
  >()
);

export const addDependencyValuesResolved = createAction(
  '[DependencyValue] Add dependency values resolved',
  props<{ addedDependencyValues: DependencyValue[] }>()
);

export const removeDependencyValues = createAction(
  '[DependencyValue] Remove dependency values',
  props<{ ids: string[] } & ActionBase<string[]>>()
);

export const removeDependencyValuesResolved = createAction(
  '[DependencyValue] Remove dependency values resolved',
  props<{ ids: string[] }>()
);
