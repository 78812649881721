import { ApprovalFlow, BaseEntity } from 'processdelight-angular-components';

export class ApprovalFlowTemplate extends BaseEntity {
  name!: string;
  approvalFlowId?: string;
  approvalFlows?: ApprovalFlow[];

  constructor(obj: Partial<ApprovalFlowTemplate>) {
    super(obj);
    Object.assign(this, obj);
  }
}
