import { DateTime } from "luxon";
import {
    BaseEntity,
    Day,
    Frequency,
    Month,
    Ranking,
} from "processdelight-angular-components";
import { Task } from "./task.model";

export class Batch extends BaseEntity {
    name!: string;
    active?: boolean;
    startDate?: DateTime;
    expiryDate?: DateTime;
    frequencyId?: string;
    frequency?: Frequency;
    amount?: number;
    closingTime?: number;
    lastRun?: DateTime;
    rankingId?: string;
    ranking?: Ranking;
    tasks?: Task[];
    days?: Day[];
    months?: Month[];

    constructor(obj: Partial<Batch>) {
        super(obj);
        Object.assign(this, obj);
    }
}
