import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import camelcaseKeys from 'camelcase-keys';
import {
  ILibraryService,
  IUploadResult,
  Library,
  LibraryTrigger,
  Permission,
} from 'processdelight-angular-components';
import { LibraryItem } from 'processdelight-angular-components/lib/library-metadata/models/library-item.model';
import { map, Observable } from 'rxjs';

@Injectable()
export class LibraryService implements ILibraryService {
  private readonly apiBase = `${location.origin}/web`;
  private readonly httpClient = inject(HttpClient);

  getLibrary(id: string): Observable<Library> {
    throw new Error('Method not implemented.');
  }
  public getLibraries(): Observable<Library[]> {
    return this.httpClient
      .get<Library[]>(`${this.apiBase}/libraries`)
      .pipe(map((m) => m.map((p) => new Library(camelcaseKeys(p)))));
  }

  public updateLibrary(library: Library): Observable<Library> {
    console.log('LibraryService.updateLibrary', library);
    throw new Error('Method not implemented.');
  }

  createLibrary(library: Library): Observable<Library> {
    console.log('LibraryService.createLibrary', library);
    throw new Error('Method not implemented.');
  }
  deleteLibrary(libraryId: string): Observable<boolean> {
    console.log('LibraryService.deleteLibrary', libraryId);
    throw new Error('Method not implemented.');
  }
  getLibraryItems(
    orderBy?: string,
    orderByDirection?: 'asc' | 'desc',
    filters?: { [key: string]: string },
    pageSize?: number,
    page?: number,
    libraryId?: string
  ): Observable<{ result: LibraryItem[]; totalRecordCount: number }> {
    console.log(
      'LibraryService.getLibraryItems',
      orderBy,
      orderByDirection,
      filters,
      pageSize,
      page,
      libraryId
    );
    throw new Error('Method not implemented.');
  }
  createLibraryItems(items: LibraryItem[]): Observable<LibraryItem[]> {
    console.log('LibraryService.createLibraryItems', items);
    throw new Error('Method not implemented.');
  }
  updateLibraryItemsForMove(items: LibraryItem[]): Observable<LibraryItem[]> {
    console.log('LibraryService.updateLibraryItemsForMove', items);
    throw new Error('Method not implemented.');
  }
  updateLibraryItems(items: LibraryItem[]): Observable<LibraryItem[]> {
    console.log('LibraryService.updateLibraryItems', items);
    throw new Error('Method not implemented.');
  }
  deleteLibraryItems(itemIds: string[]): Observable<boolean> {
    console.log('LibraryService.deleteLibraryItems', itemIds);
    throw new Error('Method not implemented.');
  }
  createLibraryPermission(
    permission: Permission,
    libraryId: string
  ): Observable<Permission> {
    console.log(
      'LibraryService.createLibraryPermission',
      permission,
      libraryId
    );
    throw new Error('Method not implemented.');
  }
  updateLibraryPermissions(
    permissions: Permission[]
  ): Observable<Permission[]> {
    console.log('LibraryService.updateLibraryPermissions', permissions);
    throw new Error('Method not implemented.');
  }
  deleteLibraryPermissions(permissionIds: string[]): Observable<boolean> {
    console.log('LibraryService.deleteLibraryPermissions', permissionIds);
    throw new Error('Method not implemented.');
  }
  getLibraryTriggers(): Observable<LibraryTrigger[]> {
    console.log('LibraryService.getLibraryTriggers');
    throw new Error('Method not implemented.');
  }
  getSharedItems(
    orderBy?: string,
    orderByDirection?: 'asc' | 'desc',
    filters?: { [key: string]: string },
    pageSize?: number,
    page?: number
  ): Observable<{ result: LibraryItem[]; totalRecordCount: number }> {
    console.log(
      'LibraryService.getSharedItems',
      orderBy,
      orderByDirection,
      filters,
      pageSize,
      page
    );
    throw new Error('Method not implemented.');
  }

  checkFileName(libraryName: string, fileName: string): Observable<boolean> {
    throw new Error('Method not implemented.');
  }
  checkUploadedFiles(
    fileName: string,
    libraryId?: string
  ): Observable<LibraryItem | undefined> {
    throw new Error('Method not implemented.');
  }
  uploadLibraryFile(
    fileName: string,
    blob: Blob,
    relativeUrl?: string
  ): Observable<IUploadResult> {
    throw new Error('Method not implemented.');
  }
  uploadFileChunk(
    fileName: string,
    relativeUrl: string | undefined,
    blob: Blob,
    start?: number,
    end?: number,
    uploadId?: string
  ): Observable<IUploadResult> {
    throw new Error('Method not implemented.');
  }
}
