import {createAction, props} from "@ngrx/store";
import { Checklist, Filter } from "processdelight-angular-components";

export const getChecklists = createAction(
  '[Checklist] Get checklists',
  props<{
    orderBy: string;
    direction: string;
    filters: Filter[];
    callback?: (checklists: Checklist[]) => void;
  }>()
);

export const getChecklistsResolved = createAction(
  '[Checklist] Get checklists resolved',
  props<{
    checklists: Checklist[];
  }>()
);

export const removeChecklists = createAction(
  '[Checklist] Remove checklists',
  props<{ id: string[]; callback?: () => void }>()
);

export const removeChecklistsResolved = createAction(
  '[Checklist] Remove checklists resolved',
  props<{ id: string[] }>()
);

export const updateChecklists = createAction(
  '[Checklist] Update checklists',
  props<{ checklists: Checklist[]; callback?: () => void }>()
);

export const updateChecklistsResolved = createAction(
  '[Checklist] Update checklists resolved',
  props<{ updatedChecklists: Checklist[] }>()
);

export const addChecklists = createAction(
  '[Checklist] Add checklists',
  props<{ 
    checklists: Checklist[]; 
    callback?: (checklists: Checklist[]) => void;
    error?: (error: any) => void;
  }>()
);

export const addChecklistsResolved = createAction(
  '[Checklist] Add checklists resolved',
  props<{ addedChecklists: Checklist[] }>()
);
