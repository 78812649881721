import { BaseEntity, ResourceFunction } from 'processdelight-angular-components';
import { ResourceUser } from './resource-user.model';

export class Resource extends BaseEntity {
  taskId!: string;
  functionId?: string;
  function?: ResourceFunction;
  resourceUserId?: string;
  resourceUser?: ResourceUser;
  taskCapacity!: number;

  constructor(obj: Partial<Resource>) {
    super(obj);
    Object.assign(this, obj);
  }
}
