import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { camelcaseKeys } from '../helpers/object.functions';
import { Checklist } from 'processdelight-angular-components';

@Injectable({ providedIn: 'root' })
export class ChecklistApiService {
  apiBase = `${location.origin}/web`;

  constructor(
    private httpClient: HttpClient,
  ) {}  

  /////////////////////////////////////////////////// POST ///////////////////////////////////////////////////
  
  // this is not used in the app yet (only in the effect)
  public addChecklists(checklists: Checklist[]): Observable<Checklist[]> {
    return this.httpClient.post<Checklist[]>(`${this.apiBase}/checklist`, checklists)
      .pipe(
        map((checklists) =>
          checklists.map(
            (checklist) => new Checklist(camelcaseKeys(checklist))
          )
        ),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /////////////////////////////////////////////////// PATCH ///////////////////////////////////////////////////
  
  // this is not used in the app yet (only in the effect)
  public updateChecklists(checklists: Checklist[]): Observable<Checklist[]> {
    return this.httpClient.patch<Checklist[]>(`${this.apiBase}/checklist`, checklists)
      .pipe(
        map((checklists) =>
          checklists.map(
            (checklist) => new Checklist(camelcaseKeys(checklist))
          )
        ),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /////////////////////////////////////////////////// DELETE ///////////////////////////////////////////////////
  
  // this is not used in the app yet (only in the effect)
  public removeChecklists(ids: string[]): Observable<string[]> {
    return this.httpClient.delete<string[]>(`${this.apiBase}/checklist`, { body: ids, });
  }
}
