import { Action, createReducer, on } from '@ngrx/store';
import { AppState } from '../../../app.reducer';
import {
  addChecklistsResolved,
  getChecklistsResolved,
  removeChecklistsResolved,
  updateChecklistsResolved,
} from './checklist.actions';
import { Checklist } from 'processdelight-angular-components';

export const featureSlice = 'checklist';

export interface State {
  checklists: Checklist[];
  checklist?: Checklist;
  ids?: string[];
}

const defaultState: State = {
  checklists: [],
  checklist: undefined,
  ids: [],
};

export function Reducer(state: State | undefined, action: Action) {
  return checklistReducer(state, action);
}

export const initialState: State = defaultState;

export const checklistReducer = createReducer(
  initialState,
  on(getChecklistsResolved, (state, { checklists }) => {
    const newChecklists = checklists.filter(
      (newItem) =>
        !state.checklists.some((existingItem) => existingItem.id === newItem.id)
    );
    return {
      ...state,
      checklists: state.checklists.concat(newChecklists),
    };
  }),
  on(removeChecklistsResolved, (state, { id }) => ({
    ...state,
    checklists: state.checklists.filter(
      (checklist) => !id.includes(checklist.id!)
    ),
  })),
  on(updateChecklistsResolved, (state, { updatedChecklists }) => ({
    ...state,
    checklists: state.checklists.map(
      (checklist) =>
        updatedChecklists.find((u) => checklist.id === u.id) ?? checklist
    ),
  })),
  on(addChecklistsResolved, (state, { addedChecklists }) => ({
    ...state,
    checklists: state.checklists.concat(addedChecklists),
  }))
);

export const checklistState = (state: AppState) => state.taskFeature.checklist;
