import { Action, createReducer, on } from '@ngrx/store';
import {
  getTasksResolved,
  addTaskResolved,
  deleteTaskResolved,
  updateTaskResolved,
  addLogsResolved,
  removeTaskDocumentsResolved,
  getAllTasksResolved,
  updateStatusesResolved,
  addStatusesResolved,
  linkSubTasksResolved,
  getKanbanResolved,
  getSubTasksResolved,
  getTaskDocumentsResolved,
  deleteStatusResolved,
  getStatusesResolved,
  createTaskDocumentsResolved,
  getTemplateTasksResolved,
} from './task.actions';
import { AppState } from 'src/app/app.reducer';
import { Status } from '../../domain/models/status.model';
import { Kanban } from '../../domain/models/task/kanban.model';
import { TemplateTask } from '../../domain/models/task/template-task.model';
import { DMSItem } from 'processdelight-angular-components';
import { Task } from '../../domain/models/task/task.model';

export const featureSlice = 'ishtarTask';

export interface State {
  tasks: Task[];
  allTasks: Task[];
  templateTasks: TemplateTask[];
  task?: Task;
  id?: string;
  pageNumber: number;
  totalRecordCount: number;
  taskDocuments: DMSItem[] | undefined;
  subTasks: Task[] | undefined;
  statuses: Status[];
  kanbanBoard?: Kanban[];
}
const defaultState: State = {
  tasks: [],
  allTasks: [],
  templateTasks: [],
  task: undefined,
  id: undefined,
  pageNumber: 0,
  totalRecordCount: 0,
  taskDocuments: undefined,
  subTasks: undefined,
  statuses: [],
  kanbanBoard: undefined,
};

export const initialState: State = defaultState;

const taskReducer = createReducer(
  initialState,
  on(
    getTasksResolved,
    (state, { tasks, pageNumber, totalRecordCount, resetPaging }) => ({
      ...state,
      tasks: resetPaging ? [...tasks] : [...state.tasks, ...tasks],
      pageNumber,
      totalRecordCount,
    })
  ),
  on(getAllTasksResolved, (state, { tasks }) => ({
    ...state,
    allTasks: tasks,
  })),
  on(getTemplateTasksResolved, (state, { tasks }) => ({
    ...state,
    templateTasks: tasks,
  })),
  on(addTaskResolved, (state, { addedTask }) => ({
    ...state,
    tasks: [...state.tasks]
      .concat(!addedTask.isTaskTemplate ? [addedTask] : [])
      .map((t) => {
        if (t.id == addedTask.parentTask?.id) {
          return new Task({
            ...t,
            subTasks: t.subTasks
              ? t.subTasks?.concat([addedTask])
              : [addedTask],
          });
        } else {
          return t;
        }
      }),
    allTasks: state.allTasks.concat([addedTask]),
  })),
  // on(addSubtaskResolved, (state, { addedTask }) => ({
  //   ...state,
  //   tasks: [addedTask].concat([...state.tasks]),
  // })),
  on(deleteTaskResolved, (state, { id }) => ({
    ...state,
    tasks: state.tasks.filter((t) => t.isDeleted || t.id != id),
    subTasks: state.subTasks
      ? [...state.subTasks.filter((t) => t.isDeleted || t.id != id)]
      : undefined,
    allTasks: state.allTasks.filter((t) => t.isDeleted || t.id != id),
  })),
  on(updateTaskResolved, (state, task) => ({
    ...state,
    tasks: state.tasks.map((t) => (t.id == task.id ? task : t)),
    allTasks: state.allTasks.map((t) => (t.id == task.id ? task : t)),
    kanbanBoard:
      state.kanbanBoard?.map((t) =>
        t.id == task.id
          ? new Kanban({
              id: task.id,
              name: task.title,
              statusId: task.statusId,
            })
          : t
      ) || undefined,
  })),
  on(linkSubTasksResolved, (state, { subTasks }) => ({
    ...state,
    subTasks: state.subTasks
      ? [
          ...state.subTasks,
          ...subTasks.filter(
            (subTask) => !state.subTasks?.some((t) => t.id === subTask.id)
          ),
        ]
      : subTasks,
    tasks: state.tasks.map((t) => {
      if (t.id == subTasks[0].parentTask?.id) {
        return new Task({
          ...t,
          subTasks: t.subTasks ? t.subTasks?.concat(subTasks) : subTasks,
        });
      } else if (t.id == subTasks[0].id) {
        return new Task({
          ...t,
          parentTaskId: subTasks[0].parentTaskId,
          parentTask: subTasks[0].parentTask,
        });
      } else {
        return t;
      }
    }),
  })),
  on(addLogsResolved, (state, { logs }) => ({
    ...state,
    tasks: state.tasks.map((t) => {
      const taskLogs = logs.filter((l) => l.taskId === t.id);
      return taskLogs.length > 0 ? new Task({ ...t, logs: taskLogs }) : t;
    }),
  })),
  on(createTaskDocumentsResolved, (state, { contract }) => ({
    ...state,
    taskDocuments: state.taskDocuments
      ? state.taskDocuments.concat([...(contract.createdItems ?? [])])
      : [...(contract.createdItems ?? [])],
  })),
  on(removeTaskDocumentsResolved, (state, { taskId, dmsAppItemIds }) => ({
    ...state,
    tasks: state.tasks.map((t) => {
      if (t.id !== taskId) return t;
      return new Task({
        ...t,
        documents: t.documents?.filter((d) =>
          dmsAppItemIds.some((id) => d.appItems?.some((i) => i.id === id))
        ),
      });
    }),
  })),
  on(deleteStatusResolved, (state, { id }) => {
    return {
      ...state,
      statuses: state.statuses?.filter((s) => s.id !== id),
    };
  }),
  on(updateStatusesResolved, (state, { updatedStatuses }) => ({
    ...state,
    statuses: state.statuses?.map(
      (s) => updatedStatuses.find((u) => s.id === u.id) ?? s
    ),
  })),
  on(addStatusesResolved, (state, { addedStatuses }) => ({
    ...state,
    statuses: addedStatuses.concat([...(state.statuses ?? [])]),
  })),
  on(getKanbanResolved, (state, { tasks }) => ({
    ...state,
    kanbanBoard: tasks,
  })),
  on(getSubTasksResolved, (state, { subTasks }) => ({
    ...state,
    subTasks: state.subTasks
      ? [
          ...state.subTasks,
          ...subTasks.filter(
            (subtask) => !state.subTasks?.some((t) => t.id === subtask.id)
          ),
        ]
      : subTasks,
  })),
  on(getTaskDocumentsResolved, (state, { taskDocuments }) => ({
    ...state,
    taskDocuments,
  })),
  on(getStatusesResolved, (state, { statuses }) => ({
    ...state,
    statuses,
  }))
);

export function Reducer(state: State | undefined, action: Action) {
  return taskReducer(state, action);
}

export const taskState = (state: AppState) => state.taskFeature.ishtarTask;
