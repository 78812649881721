import { createAction, props } from '@ngrx/store';
import { ActionBase } from '../action-base.interface';
import { ApprovalFlow } from 'processdelight-angular-components';

export const getApprovalFlows = createAction(
  '[ApprovalFlow] Get approval flows',
  props<{
    taskId: string;
    callback?: (approvalFlows: ApprovalFlow[]) => void;
    error?: (error: any) => void;
  }>()
);
export const getApprovalFlowsResolved = createAction(
  '[ApprovalFlow] Get approval flows resolved',
  props<{
    taskId: string;
    approvalFlows: ApprovalFlow[];
  }>()
);

export const saveApprovalFlows = createAction(
  '[ApprovalFlow] Save approval flows',
  props<
    {
      taskId: string;
      approvalFlows: ApprovalFlow[];
    } & ActionBase<{
      taskId: string;
      approvalFlows: ApprovalFlow[];
    }>
  >()
);
export const saveApprovalFlowsResolved = createAction(
  '[ApprovalFlow] Save approval flows resolved',
  props<{
    taskId: string;
    approvalFlows: ApprovalFlow[];
  }>()
);
