import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { ApprovalFlowTemplate } from '../domain/models/task/decision/approval-flow-template.model';
import { camelcaseKeys } from '../helpers/object.functions';
import { ApprovalFlow } from 'processdelight-angular-components';

@Injectable({ providedIn: 'root' })
export class ApprovalFlowApiService {
  apiBase = `${location.origin}/web`;

  constructor(
    private httpClient: HttpClient
  ) {}

  /////////////////////////////////////////////////// GET ///////////////////////////////////////////////////
  public getApprovalFlows(taskId: string): Observable<ApprovalFlow[]> {
    return this.httpClient.get<ApprovalFlow[]>(`${this.apiBase}/task/${taskId}/approval-flow`)
      .pipe(
        map((approvalFlows) => 
          approvalFlows.map((a) => new ApprovalFlow(a)
        )),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /////////////////////////////////////////////////// POST ///////////////////////////////////////////////////
  public saveApprovalFlows(taskId: string, approvalFlows: ApprovalFlow[]): Observable<ApprovalFlow[]> {
    return this.httpClient
      .post<ApprovalFlow[]>(`${this.apiBase}/task/${taskId}/approval-flow`, approvalFlows)
      .pipe(
        map((approvalFlows) => 
          approvalFlows.map((a) => new ApprovalFlow(camelcaseKeys(a)))
        ),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  
  // this method is not used yet 
  public getApprovalFlowTemplates(): Observable<ApprovalFlowTemplate[]> {
    return this.httpClient.get<ApprovalFlowTemplate[]>(`${this.apiBase}/approval-flow-template`)
      .pipe(
        map((approvalFlows) => 
          approvalFlows.map((a) => new ApprovalFlowTemplate(a))
        ),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  // this method is not used yet
  public saveApprovalFlowsAsTemplate(template: ApprovalFlowTemplate) {
    return this.httpClient
      .post<ApprovalFlowTemplate>(`${this.apiBase}/approval-flow-template`, template)
      .pipe(
        map((template) => new ApprovalFlowTemplate(camelcaseKeys(template))),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
}
