import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
  IMetadataService,
  Metadata,
  MetadataChoice,
  MetadataChoiceTranslation,
  MetadataParam,
  MetadataPermission,
  MetadataPermissionConfiguration,
} from 'processdelight-angular-components';
import { map, Observable } from 'rxjs';

@Injectable()
export class MetadataService implements IMetadataService {
  private readonly apiBase = `${location.origin}/web`;
  private readonly httpClient = inject(HttpClient);

  public getMetadataParams(getAll?: boolean): Observable<MetadataParam[]> {
    getAll ??= true;
    return this.httpClient
      .get<MetadataParam[]>(
        `${this.apiBase}/metadata/parameters?getAll=${getAll}`
      )
      .pipe(map((params) => params.map((p) => new MetadataParam(p))));
  }

  public createMetadataChoice(
    choice: MetadataChoice
  ): Observable<MetadataChoice> {
    console.log(choice);
    throw new Error('Method not implemented.');
  }

  createMetadataParam(metadataParam: MetadataParam): Observable<MetadataParam> {
    console.log(metadataParam);
    throw new Error('Method not implemented.');
  }
  updateMetadataParam(metadataParam: MetadataParam): Observable<MetadataParam> {
    console.log(metadataParam);
    throw new Error('Method not implemented.');
  }
  deleteMetadataParam(metadataParamId: string): Observable<boolean> {
    console.log(metadataParamId);
    throw new Error('Method not implemented.');
  }
  getMetadataPermissionConfigurations(): Observable<
    MetadataPermissionConfiguration[]
  > {
    throw new Error('Method not implemented.');
  }
  updateMetadataPermissions(
    permissions: MetadataPermission[]
  ): Observable<MetadataPermission[]> {
    console.log(permissions);
    throw new Error('Method not implemented.');
  }
  updateMetadataPermissionConfiguration(
    permissionConfig: MetadataPermissionConfiguration
  ): Observable<MetadataPermissionConfiguration> {
    console.log(permissionConfig);
    throw new Error('Method not implemented.');
  }
  updateMetadataChoice(choice: MetadataChoice): Observable<MetadataChoice> {
    console.log(choice);
    throw new Error('Method not implemented.');
  }
  updateMetadataChoices(
    choices: MetadataChoice[]
  ): Observable<MetadataChoice[]> {
    console.log(choices);
    throw new Error('Method not implemented.');
  }
  deleteMetadataChoice(paramId: string, choiceId: string): Observable<boolean> {
    console.log(paramId, choiceId);
    throw new Error('Method not implemented.');
  }
  createMetadataChoiceTranslations(
    translations: MetadataChoiceTranslation[]
  ): Observable<MetadataChoiceTranslation[]> {
    console.log(translations);
    throw new Error('Method not implemented.');
  }
  checkExistingMetadata(
    metadata: Metadata[],
    fileExtension: string,
    excludedId?: string,
    libraryId?: string
  ): Observable<string | undefined> {
    throw new Error('Method not implemented.');
  }
}
