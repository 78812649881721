import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { Skill } from '../domain/models/skill.model';
import { ActionType } from '../domain/models/task/decision/action-type.model';
import { LogType } from '../domain/models/task/log-type.model';
import {
  ApprovalType,
  Country,
  Day,
  DependencyType,
  Frequency,
  MetadataParam,
  Language,
  Month,
  Ranking,
  RegistrationType,
  TaskType,
} from 'processdelight-angular-components';
import { ResourceThing } from '../domain/models/resource/resource-thing.model';
import { ResourceUser } from '../domain/models/resource/resource-user.model';
import { StatusType } from '../domain/models/status-type.model';

@Injectable({ providedIn: 'root' })
export class TypesApiService {
  apiBase = `${location.origin}/web/type`;

  constructor(private httpClient: HttpClient) {}

  /////////////////////////////////////////////////// GET ///////////////////////////////////////////////////

  public getProjects(): Observable<
    { id?: string; projectName: string; projectId?: string }[]
  > {
    const url = `${this.apiBase}/project`;
    return this.httpClient
      .get<{ id?: string; projectName: string; projectId?: string }[]>(url)
      .pipe(catchError((error) => throwError(() => error)));
  }

  public getSkills(): Observable<Skill[]> {
    const url = `${this.apiBase}/skill`;
    return this.httpClient.get<Skill[]>(url).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getTaskTypes(): Observable<TaskType[]> {
    const url = `${this.apiBase}/task`;
    return this.httpClient.get<TaskType[]>(url).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getRegistrationTypes(): Observable<RegistrationType[]> {
    const url = `${this.apiBase}/registration`;
    return this.httpClient.get<RegistrationType[]>(url).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getDependencyTypes(): Observable<DependencyType[]> {
    const url = `${this.apiBase}/dependency`;
    return this.httpClient.get<DependencyType[]>(url).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getActionTypes(): Observable<ActionType[]> {
    const url = `${this.apiBase}/action`;
    return this.httpClient.get<ActionType[]>(url).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getLogTypes(): Observable<LogType[]> {
    const url = `${this.apiBase}/log`;
    return this.httpClient.get<LogType[]>(url).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getApprovalTypes(): Observable<ApprovalType[]> {
    const url = `${this.apiBase}/approval`;
    return this.httpClient.get<ApprovalType[]>(url).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getStatusTypes(): Observable<StatusType[]> {
    const url = `${this.apiBase}/status`;
    return this.httpClient.get<StatusType[]>(url).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getMetadataParameters(): Observable<MetadataParam[]> {
    const url = `${this.apiBase}/metadata-parameter`;
    return this.httpClient.get<MetadataParam[]>(url).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getDays(): Observable<Day[]> {
    const url = `${this.apiBase}/day`;
    return this.httpClient.get<Day[]>(url).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getMonths(): Observable<Month[]> {
    const url = `${this.apiBase}/month`;
    return this.httpClient.get<Month[]>(url).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getRankings(): Observable<Ranking[]> {
    const url = `${this.apiBase}/ranking`;
    return this.httpClient.get<Ranking[]>(url).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getFrequencies(): Observable<Frequency[]> {
    const url = `${this.apiBase}/frequency`;
    return this.httpClient.get<Frequency[]>(url).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getResourceThings(): Observable<ResourceThing[]> {
    const url = `${this.apiBase}/resource-thing`;
    return this.httpClient.get<ResourceThing[]>(url).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getResourceUsers(): Observable<ResourceUser[]> {
    const url = `${this.apiBase}/resource-user`;
    return this.httpClient.get<ResourceUser[]>(url).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  public getCountries(): Observable<Country[]> {
    return this.httpClient.get<Country[]>(`${this.apiBase}/country`);
  }

  public getLanguages(): Observable<Language[]> {
    return this.httpClient.get<Language[]>(`${this.apiBase}/language`);
  }
}
