import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { map, Observable, Subject } from 'rxjs';
import { AppState } from 'src/app/app.reducer';

import {
  addStatuses,
  addTask,
  addLogs,
  createTaskDocuments,
  deleteStatus,
  deleteTask,
  getAllTasks,
  getKanban,
  getStatuses,
  getSubTasks,
  getTaskById,
  getTaskDocuments,
  getTasks,
  linkSubTasks,
  removeTaskDocuments,
  updateStatuses,
  updateTask,
  updateTaskStatus,
  getTemplateTasks,
} from './task.actions';
import { taskState } from './task.reducer';
import { BaseFacade } from '../base.facade';
import { Status } from '../../domain/models/status.model';
import { DMSItem, Filter, Log, Metadata } from 'processdelight-angular-components';
import { Task } from '../../domain/models/task/task.model';

@Injectable({ providedIn: 'root' })
export class TaskFacade extends BaseFacade {
  tasks$ = this._store.pipe(
    select(taskState),
    map((state) => state.tasks.filter((t) => !t.isDeleted))
  );

  templates$ = this._store.pipe(
    select(taskState),
    map((state) => state.templateTasks)
  );

  allTasks$ = this._store.pipe(
    select(taskState),
    map((state) => state.allTasks)
  );

  activeTasks$ = this.allTasks$.pipe(
    map((task) => task.filter((t) => !t.status?.isFinalState))
  );

  pageNumber$ = this._store.pipe(
    select(taskState),
    map((state) => state.pageNumber)
  );

  totalRecordCount$ = this._store.pipe(
    select(taskState),
    map((state) => state.totalRecordCount)
  );

  taskDocuments$ = this._store.pipe(
    select(taskState),
    map((state) => state.taskDocuments)
  );

  subTasks$ = this._store.pipe(
    select(taskState),
    map((state) => state.subTasks)
  );

  statuses$ = this._store.pipe(
    select(taskState),
    map((state) => state.statuses)
  );

  kanban$ = this._store.pipe(
    select(taskState),
    map((state) => state.kanbanBoard)
  );

  constructor(_store: Store<AppState>) {
    super(_store);
  }

  getTasks(
    assignedToMe: boolean,
    orderBy: string,
    direction: string,
    filters: Filter[],
    pageSize: number,
    page: number,
    resetPaging?: boolean
  ): Observable<Task[]> {
    const subject = new Subject<Task[]>();
    this._store.dispatch(
      getTasks({
        assignedToMe,
        orderBy,
        direction,
        filters,
        pageSize,
        page,
        resetPaging,
        callback: (tasks) => {
          subject.next(tasks);
          subject.complete();
        },
        errorCallback: (error) => {
          subject.error(error);
        },
      })
    );
    return subject.asObservable();
  }

  getAllTasks(): Observable<void> {
    const subject = new Subject<void>();
    this._store.dispatch(
      getAllTasks({
        callback: () => {
          subject.next();
          subject.complete();
        },
        errorCallback: (error) => {
          subject.error(error);
        },
      })
    );
    return subject.asObservable();
  }

  getTemplateTasks(): Observable<void> {
    const subject = new Subject<void>();
    this._store.dispatch(
      getTemplateTasks({
        callback: () => {
          subject.next();
          subject.complete();
        },
        errorCallback: (error) => {
          subject.error(error);
        },
      })
    );
    return subject.asObservable();
  }

  getTaskById(id: string) {
    return this.dispatchAction$(getTaskById, { id });
  }

  getKanban(assignedToMe: boolean) {
    return this.dispatchAction$(getKanban, { assignedToMe });
  }

  addTask$(task: Task, isTeamsMeeting = false): Observable<Task> {
    const subject = new Subject<Task>();
    this._store.dispatch(
      addTask({
        isTeamsMeeting,
        task,
        callback: (addedTask) => {
          subject.next(addedTask);
          subject.complete();
        },
        errorCallback: (error) => {
          subject.error(error);
        },
      })
    );
    return subject.asObservable();
  }

  removeTask(id: string): Observable<string> {
    return new Observable<string>((observer) => {
      this._store.dispatch(
        deleteTask({
          id,
          callback: (taskId) => {
            observer.next(taskId);
            observer.complete();
          },
          errorCallback: (error) => {
            observer.error(error);
          },
        })
      );
    });
  }

  updateTask$(task: Task): Observable<Task> {
    const subject = new Subject<Task>();
    this._store.dispatch(
      updateTask({
        task,
        callback: (updatedTask) => {
          subject.next(updatedTask);
          subject.complete();
        },
        errorCallback: (error) => {
          subject.error(error);
        },
      })
    );
    return subject.asObservable();
  }

  updateTaskStatus$(taskId: string, statusId: string) {
    return this.dispatchAction$(updateTaskStatus, { taskId, statusId });
  }

  addLogs$(logs: Log[]) {
    return this.dispatchAction$(addLogs, { logs });
  }

  getTaskDocuments(taskId: string): Observable<DMSItem[]> {
    const subject = new Subject<DMSItem[]>();
    this._store.dispatch(
      getTaskDocuments({
        taskId,
        callback: (taskDocuments) => {
          subject.next(taskDocuments);
          subject.complete();
        },
        errorCallback: (e) => {
          subject.error(e);
        },
      })
    );
    return subject.asObservable();
  }

  createTaskDocuments(
    documents: {
      taskId: string;
      filename: string;
      fileLocation: string;
      sharepointId: string;
    }[]
  ) {
    const subject = new Subject<{
      createdItems: DMSItem[];
      updatedMetadata: Metadata[];
    }>();
    this._store.dispatch(
      createTaskDocuments({
        documents,
        callback: (response) => {
          subject.next(response);
          subject.complete();
        },
        error: (error) => {
          subject.error(error);
        },
      })
    );
    return subject.asObservable();
  }

  removeTaskDocuments(taskId: string, dmsAppItemIds: string[]) {
    const subject = new Subject<string[]>();
    this._store.dispatch(
      removeTaskDocuments({
        taskId,
        dmsAppItemIds,
        callback: (response) => {
          subject.next(response);
          subject.complete();
        },
        error: (error) => {
          subject.error(error);
        },
      })
    );
    return subject.asObservable();
  }

  linkSubTasks(taskId: string, subTaskIds: string[]) {
    return this.dispatchAction$(linkSubTasks, { taskId, subTaskIds });
  }

  getSubTasks(taskId: string): Observable<Task[]> {
    const subject = new Subject<Task[]>();
    this._store.dispatch(
      getSubTasks({
        taskId,
        callback: (subTasks) => {
          subject.next(subTasks);
          subject.complete();
        },
        errorCallback: (e) => {
          subject.error(e);
        },
      })
    );
    return subject.asObservable();
  }

  addStatuses(statuses: Status[]): Observable<Status[]> {
    const subject = new Subject<Status[]>();
    this._store.dispatch(
      addStatuses({
        statuses,
        callback: (addedStatuses) => {
          subject.next(addedStatuses);
          subject.complete();
        },
        error: (e) => {
          subject.error(e);
        },
      })
    );
    return subject.asObservable();
  }

  getStatuses(): Observable<Status[]> {
    return this.dispatchAction$(getStatuses);
  }

  removeStatus(id: string): Observable<string> {
    const subject = new Subject<string>();
    this._store.dispatch(
      deleteStatus({
        id,
        callback: (statusId) => {
          subject.next(statusId);
          subject.complete();
        },
        error: (error) => {
          subject.error(error);
        },
      })
    );
    return subject.asObservable();
  }

  updateStatuses(statuses: Status[]): Observable<Status[]> {
    const subject = new Subject<Status[]>();
    this._store.dispatch(
      updateStatuses({
        statuses,
        callback: (updatedStatuses) => {
          subject.next(updatedStatuses);
          subject.complete();
        },
        error: (e) => {
          subject.error(e);
        },
      })
    );
    return subject.asObservable();
  }
}
