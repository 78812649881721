import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { map, Subject } from 'rxjs';
import { AppState } from '../../../app.reducer';
import { checklistState } from './checklist.reducer';
import { addChecklists, getChecklists, removeChecklists, updateChecklists } from './checklist.actions';
import { Checklist, Filter } from 'processdelight-angular-components';

@Injectable({ providedIn: 'root' })
export class ChecklistFacade {
  checklists$ = this._store.pipe(
    select(checklistState),
    map((state) => state.checklists)
  );

  constructor(private _store: Store<AppState>) {}

  getChecklists$(    
    orderBy: string,
    direction: string,
    filters: Filter[]
  ) {
    const subject = new Subject<Checklist[]>();
    this._store.dispatch(
      getChecklists({
        orderBy,
        direction,
        filters,
        callback: (checklists) => {
          subject.next(checklists);
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }

  addChecklists$(checklists: Checklist[]) {
    const subject = new Subject<Checklist[]>();
    this._store.dispatch(
      addChecklists({
        checklists,
        callback: (checklists) => {
          subject.next(checklists);
          subject.complete();
        },
        error: (error) => {
          subject.error(error);
        },
      })
    );
    return subject.asObservable();
  }

  removeChecklists$(id: string[]) {
    const subject = new Subject<void>();
    this._store.dispatch(
      removeChecklists({
        id,
        callback: () => {
          subject.next();
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }

  updateChecklists(checklists: Checklist[]) {
    this._store.dispatch(updateChecklists({ checklists }));
  }
}
