import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { camelcaseKeys } from '../helpers/object.functions';
import { Dependency, DependencyValue } from 'processdelight-angular-components';

@Injectable({ providedIn: 'root' })
export class DependencyApiService {
  apiBase = `${location.origin}/web`;

  constructor(
    private httpClient: HttpClient
  ) {}  

  /////////////////////////////////////////////////// POST ///////////////////////////////////////////////////
  public addDependencies(dependencies: Dependency[]): Observable<Dependency[]> {
    return this.httpClient
      .post<Dependency[]>(`${this.apiBase}/dependency`, dependencies)
      .pipe(
        map((dependencies) =>
          dependencies.map(
            (d) => new Dependency(camelcaseKeys(d))
          )
        ),
        catchError((error) => {
          return throwError(error);
        })
      );
  }  
  
  public addDependencyValues(dependencyValues: DependencyValue[]): Observable<DependencyValue[]> {
    return this.httpClient
      .post<DependencyValue[]>(`${this.apiBase}/dependency-value`, dependencyValues)
      .pipe(
        map((dependencyValues) =>
          dependencyValues.map(
            (dependencyValue) => new DependencyValue(camelcaseKeys(dependencyValue))
          )
        ),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /////////////////////////////////////////////////// PATCH ///////////////////////////////////////////////////
  public updateDependencies(dependencies: Dependency[]): Observable<Dependency[]> {
    return this.httpClient
      .patch<Dependency[]>(`${this.apiBase}/dependency`, dependencies)
      .pipe(
        map((dependencies) =>
          dependencies.map(
            (d) => new Dependency(camelcaseKeys(d))
          )
        ),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  // this is not used in the app yet (only in the effect)
  public updateDependencyValues(dependencyValues: DependencyValue[]): Observable<DependencyValue[]> {
    return this.httpClient
    .patch<DependencyValue[]>(`${this.apiBase}/dependency-value`, dependencyValues)
    .pipe(
      map((dependencyValues) =>
        dependencyValues.map(
          (dependencyValue) => new DependencyValue(camelcaseKeys(dependencyValue))
        )
      ),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  /////////////////////////////////////////////////// DELETE ///////////////////////////////////////////////////
  public removeDependencies(ids: string[]): Observable<string[]> {
    return this.httpClient.delete<string[]>(`${this.apiBase}/dependency`, { body: ids, })
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public removeDependencyValues(ids: string[]): Observable<string[]> {
    return this.httpClient.delete<string[]>(`${this.apiBase}/dependency/addDependencies`, { body: ids, })
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }
}
